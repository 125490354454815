import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authRoutes } from './pages/auth/auth.routing';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'onpremstats', loadChildren: () => import('./pages/onpremstats/onpremstats.module').then(m => m.OnPremStatsModule) },
            { path: 'updaterequest', loadChildren: () => import('./pages/updaterequest/updaterequest.module').then(m => m.UpdateRequestModule) },
            // { path: 'precedents', loadChildren: () => import('./pages/precedents/precedents.module').then(m => m.PrecedentsModule) },

            { path: '', pathMatch: 'full', redirectTo: 'onpremstats' }
        ]
    },
    {
        path: 'auth',
        children: [
            ...authRoutes
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
